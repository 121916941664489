/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  AccountResponse,
  ActivateCardRequest,
  AddReservationRequest,
  AssignCreditRequest,
  CancelReservationRequest,
  CardImageResponse,
  CardResponse,
  PauseCardRequest,
  ReportLostStolenRequest,
  ReservationDto,
  ReservationResponse,
  UpdateReservationRequest,
} from "../models"

export const activateCard = {
  url: "/v1/activateCard",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: ActivateCardRequest
    res: CardResponse
  },
} as const

export const addReservation = {
  url: "/v1/addReservation",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: AddReservationRequest
    res: ReservationResponse
  },
} as const

export const assignCredit = {
  url: "/v1/assignCredit",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: AssignCreditRequest
    res: AccountResponse
  },
} as const

export const cancelReservation = {
  url: "/v1/cancelReservation",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: CancelReservationRequest
    res: ReservationResponse
  },
} as const

export const getAllReservationsSent = {
  url: "/v1/getAllReservationsSent",
  method: "GET",
  meta: {} as {
    queryParams: {
      ident: string
    }
    res: Array<ReservationDto>
  },
} as const

export const getCardImageURL = {
  url: "/v1/cardImageURL",
  method: "GET",
  meta: {} as {
    res: CardImageResponse
  },
} as const

export const pauseCard = {
  url: "/v1/pauseCard",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: PauseCardRequest
    res: CardResponse
  },
} as const

export const reportLostStolen = {
  url: "/v1/reportLostStolen",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: ReportLostStolenRequest
    res: CardResponse
  },
} as const

export const updateReservation = {
  url: "/v1/updateReservation",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: UpdateReservationRequest
    res: ReservationResponse
  },
} as const
